import React from "react"
import Layout from "../components/layout"
import ListadoProyectosPageArte from "../components/listadoProyectosPageArte"
import HeroProyectos from "../components/heroProyectos"
import SEO from "../components/seo"
const ProyectosPage = () => {
  return (
    <Layout>
      <SEO
        title="Ainoa Molina - Proyectos arte & ideas"
        description="Estos son todos mis proyectos de arte & ideas"
      />
      <HeroProyectos />
      <ListadoProyectosPageArte />
    </Layout>
  )
}

export default ProyectosPage
