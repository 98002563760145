import React from 'react'
import styled from '@emotion/styled'
import * as vars from '../styles/vars'
import Links from './linksArte'
import ProjectsBoxPageArte from './projectBoxPageArte'

const ListadoProyectosPage = () => {


    return ( 
        <ProjectHomeSection className="projects">
            <div className="container">
                <Links />
                <ProjectsBoxPageArte />
            </div>
        </ProjectHomeSection>
     )
}
 
export default ListadoProyectosPage

const ProjectHomeSection = styled.section`
    margin-bottom: 50px;
    ${vars.sm}{
        margin-bottom: 100px;
    }
    ${vars.md}{
        margin-bottom: 75px;
    }
    ${vars.lg}{
        margin-bottom: 70px;
    }
    ${vars.xl}{
        margin-bottom: 100px;
    }
    ${vars.xxl}{
        margin-bottom: 150px;
    }
`